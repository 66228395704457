import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  CarOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  SettingOutlined,
  WalletOutlined,
  PhoneOutlined,
  BellOutlined,
  TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/service",
    sidebar: {
      icon: <WalletOutlined />,
      label: "예약/서비스 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "예약 관리",
        },
        component: loadable(() => import("../pages/service/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/service/Detail")),
      },
      {
        exact: true,
        path: "/estimate",
        sidebar: {
          label: "견적 관리",
        },
        component: loadable(() => import("../pages/service/estimate/List")),
      },
      {
        exact: true,
        path: "/estimate/detail/:id",
        visible: false,
        component: loadable(() => import("../pages/service/estimate/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/rentcar",
    sidebar: {
      icon: <CarOutlined />,
      label: "렌트카 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "렌트카 목록",
        },
        component: loadable(() => import("../pages/rent/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/rent/Detail")),
      },
      {
        exact: true,
        path: "/reserve",
        sidebar: {
          label: "렌트카 예약관리",
        },
        component: loadable(() => import("../pages/rent/reserve/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/reserve/detail/:id",
        component: loadable(() => import("../pages/rent/reserve/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/pay",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "요금 관리",
    },
    children: [
      {
        exact: true,
        path: "/storage/jeju",
        sidebar: {
          label: "차량보관 요금관리(제주)",
        },
        component: loadable(() => import("../pages/pay/storage/jeju/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/storage/jeju/modify",
        component: loadable(() => import("../pages/pay/storage/jeju/Modify")),
      },
      {
        exact: true,
        path: "/storage/gimpo",
        sidebar: {
          label: "차량보관 요금관리(김포)",
        },
        component: loadable(() => import("../pages/pay/storage/gimpo/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/storage/gimpo/modify",
        component: loadable(() => import("../pages/pay/storage/gimpo/Modify")),
      },
      {
        exact: true,
        path: "/storage/gimhae",
        sidebar: {
          label: "차량보관 요금관리(김해)",
        },
        component: loadable(() => import("../pages/pay/storage/gimhae/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/storage/gimhae/modify",
        component: loadable(() => import("../pages/pay/storage/gimhae/Modify")),
      },
      {
        exact: true,
        path: "/storage/qty",
        sidebar: {
          label: "차량 보관 대수 관리",
        },
        component: loadable(() => import("../pages/pay/storage/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "배너 설정",
        },
        sub: [
          {
            exact: true,
            path: "/main",
            sidebar: {
              label: "메인 배너 관리",
            },
            component: loadable(() => import("../pages/site/banner/main/List")),
          },
          {
            exact: true,
            visible: false,
            path: "/main/modify/:id",
            component: loadable(() => import("../pages/site/banner/main/Modify")),
          },
          {
            exact: true,
            visible: false,
            path: "/main/register/",
            component: loadable(() => import("../pages/site/banner/main/Register")),
          },
          {
            exact: true,
            path: "/middle",
            sidebar: {
              label: "중간 배너 관리",
            },
            component: loadable(() => import("../pages/site/banner/middle/List")),
          },
          {
            exact: true,
            visible: false,
            path: "/middle/modify/:id",
            component: loadable(() => import("../pages/site/banner/middle/Modify")),
          },
          {
            exact: true,
            visible: false,
            path: "/middle/register",
            component: loadable(() => import("../pages/site/banner/middle/Register")),
          },
        ],
      },
      {
        exact: true,
        path: "/guide",
        sidebar: {
          label: "이용안내 관리",
        },
        sub: [
          {
            exact: true,
            path: "/parking",
            sidebar: {
              label: "주차대행",
            },
            component: loadable(() => import("../pages/site/guide/Parking")),
          },
          {
            exact: true,
            path: "/jejuService",
            sidebar: {
              label: "제주서비스(제주)",
            },
            component: loadable(() => import("../pages/site/guide/JejuService")),
          },/*
          {
            exact: true,
            path: "/trip",
            sidebar: {
              label: "출장정비",
            },
            component: loadable(() => import("../pages/site/guide/Trip")),
          },
          {
            exact: true,
            path: "/trip/wash",
            sidebar: {
              label: "출장세차",
            },
            component: loadable(() => import("../pages/site/guide/TripWash")),
          },*/
          {
            exact: true,
            path: "/rent",
            sidebar: {
              label: "렌터카",
            },
            component: loadable(() => import("../pages/site/guide/Rent")),
          },
        ],
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "약관 및 푸터 관리",
        },
        sub: [
          {
            exact: true,
            path: "/term",
            sidebar: {
              label: "약관 관리",
            },
            component: loadable(() => import("../pages/site/term/List")),
          },
          {
            exact: true,
            path: "/footer",
            sidebar: {
              label: "푸터 관리",
            },
            component: loadable(() => import("../pages/site/footer/List")),
          },
        ],
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <PhoneOutlined />,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항 관리",
        },
        component: loadable(() => import("../pages/cs/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/register",
        component: loadable(() => import("../pages/cs/notice/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/modify/:id",
        component: loadable(() => import("../pages/cs/notice/Modify")),
      },
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1문의 관리",
        },
        component: loadable(() => import("../pages/cs/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/cs/qna/Detail")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 관리",
        },
        component: loadable(() => import("../pages/cs/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/cs/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/modify/:id",
        component: loadable(() => import("../pages/cs/faq/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <TeamOutlined />,
      label: "관리자 설정",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "관리자 목록",
        },
        component: loadable(() => import("../pages/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/admin/Modify")),
      },
    ],
  },
];
